import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Conf1 from '../img/conf-1.png';
import Conf2 from '../img/conf-2.png';
import Conf3 from '../img/conf-3.png';
import HeroLogo from '../img/img-giving-life.png';
import IllBabies from '../img/baby-gfx.png';
import ImgGift from '../img/img-gift.png';
import ImgGiving from '../img/img-baby-face.png';
import ImgChristmasTree from '../img/img-christmas-tree.png';
import ImgTesti1 from '../img/img-testi1.png';
import ImgTesti2 from '../img/img-testi2.png';
import ImgTesti3 from '../img/img-testi3.png';
import ImgTesti4 from '../img/img-testi4.png';
import ImgTesti5 from '../img/img-testi5.png';
import SnowmanLight from '../img/float-snowman-light.png';
import SnowmanDark from '../img/float-snowman-dark.png';
import Snowflake from '../img/float-snowflake.png';

import HelmetComponent from '../components/helmetcomponent';
import Layout from '../components/layout';
import NotifModal from '../components/notifmodal';
import SubscribeModal from '../components/subscribemodal';

import SponsorModal from '../components/sponsorModal';
import LGLForm from '../components/LGLForm';

const pageUrls = require('../constants')

export default function Template({ data }) {
  const [modalShow, setModalShow] = useState(false);
  const [notifModalShow, setNotifModalShow] = useState(false);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [sponsorModalShow, setSponsorModalShow] = useState(false);

  const handleChangeFirstname = e => {
    setFirstname(e.target.value);
  };

  const handleChangeLastname = e => {
    setLastname(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };
  

  // This handles subscription to newsletter when clicking submit
  const handleSubmit = async event => {
    event.preventDefault();
    await addToMailchimp(email, { FNAME: firstname || '', LNAME: lastname || '' });
    setModalShow(false);
    setNotifModalShow(true);
  };

  const { totalAmount } = data.allContentfulGivingTuesday2020.edges[0].node;
  const formatAmount = totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const percentBar = 100 * (totalAmount / 20000);

  return (
    <ParallaxProvider>
    <Layout nav="colored-nav">
      <HelmetComponent title="Giving Life, Health and Hope" description="On December 1st, Tuesday after Thanksgiving, we are raising $20,000 to provide important lifesaving care to 80 mothers and babies of Cebu, Philippines." />
      <div>
        <section id="christmas-hero" className="mt130 mb60">
          <div className="container">
            <div className="row d-flex align-items-center mobile-reverse">
              <div className="col-md-6 col-sm-12 text-center">
                <img src={HeroLogo} alt="giving-thanks" className="mt30" width="80%"/>
                <div>
                  <h3 className="mb10 mt20 f22">
                    <span className="bold">${formatAmount}</span> of $20,000
                  </h3>
                  <div className="progress">
                    <div className="progress-bar" style={{width:`${percentBar}%`}}></div>
                  </div>
                  <SponsorModal id='donate-now-modal' modalShow={sponsorModalShow} onHide={() => {
                      window.location.hash = "";
                      setSponsorModalShow(false);
                      setTimeout(() => {
                        window.location.hash = "sponsorNow";
                      }, 200);
                    }} title="Sponsor Now" body={sponsorModalShow && <LGLForm formId={"u_gKPNaPjCmXFlaF6PULrw"} initialHeight={767} formPrefix={"https://secure.lglforms.com/form_engine"} />} />
                  <a
                    className="btn-donate btn-reg btn-slim btn-purple w-40 mt20 mb40"
                    onClick={() => {
                      setSponsorModalShow(true);
                    }}
                  >Give Today</a>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={ImgGiving} alt="giving-thanks" className="img-gt mb30"/>
              </div>
            </div>
          </div>
        </section>

        <section id="givingthanks-intro" className="mt80 mb-0">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-10 offset-md-1 col-sm-12 text-center">
                <h2 className="mb30 satisfy-font purple">
                  Merry Christmas and a Blessed 2021!
                </h2>
                <p className="gt-p">
                On <span className="purple">#GivingTuesday</span>, we raised $15,975 of our $20,000 goal! This will enable us to provide important lifesaving care for 80 babies and moms in Cebu, Philippines. <strong>WE COULDN’T HAVE DONE IT WITHOUT YOUR SUPPORT!</strong><br/><br/>If you weren’t able to give on <span className="purple">#GivingTuesday</span>, you can still contribute and Give the Gift of Life, Health, and Hope to more babies this Christmas!
                </p>
              </div>
            </div>
          </div>
        </section>
  
        <section id="christmas-fw" className="mtn50 mb-0">

          <div className="parallax-container">
            <Parallax className="heart-size floating-snow1" y={[900, 100]} tagOuter="figure">
              <img src={SnowmanLight} alt="giving-thanks"/>
            </Parallax>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                
                <img src={ImgChristmasTree} className="christmas-tree" alt="giving-thanks" width="55%"/>
                
              </div>
            </div>
          </div>
          
          <div className="parallax-container">
            <Parallax className="heart-size floating-snow2" y={[10, -950]} tagOuter="figure">
              <img src={SnowmanDark} alt="giving-thanks"/>
            </Parallax>
            <Parallax className="heart-size floating-snow3" y={[10, -900]} tagOuter="figure">
              <img src={Snowflake} alt="giving-thanks"/>
            </Parallax>
          </div>

        </section>

        <section id="sample-gifts" className="mt100 mb100">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <img src={ImgGift} alt="giving-thanks" className="img-gt"/>
                <h4 className="mt20 bold">
                  Here's what your life saving gift can do:
                </h4>
              </div>
            </div>
            <div className="row">

              <div className="col-sm-12 col-md-3 text-center mt40">
                <h4 className="purple bold">
                  $350
                </h4>
                <p className="gt-p">
                  Safe Delivery Package including labor and delivery services provided by a certified healthcare worker, use of the recovery room, COVID-19 testing
                </p>
              </div>

              <div className="col-sm-12 col-md-3 text-center mt40">
                <h4 className="purple bold">
                  $150
                </h4>
                <p className="gt-p">
                  Well Baby Check-up to make sure baby is healthy upon delivery
                </p>
              </div>

              <div className="col-sm-12 col-md-3 text-center mt40">
                <h4 className="purple bold">
                  $35
                </h4>
                <p className="gt-p">
                Sponsor A Mom (1 Prenatal Check-up) to ensure mother is healthy during her pregnancy
                </p>
              </div>

              <div className="col-sm-12 col-md-3 text-center mt40">
                <h4 className="purple bold">
                  $25
                </h4>
                <p className="gt-p">
                  Newborn Hearing Screening
                </p>
              </div>

            </div>

            <div className="row mt40">
              <div className="col-sm-2 offset-sm-5">
                <a
                  className="btn-donate btn-reg btn-slim btn-purple mb20"
                  onClick={() => {
                    setSponsorModalShow(true);
                  }}
                >
                  Give Today
                </a>
              </div>
            </div>

            <div className="row mt30">
              <div className="col-sm-10 offset-sm-1 bank-details">
                <div className="row text-center">
                  <div className="col-sm-12 col-md-5 offset-md-1 mt30 mb20">
                    <p className="gt-p">
                      <span className="bold gray">
                        Donate from Philippines: <br /><br />
                      </span>
                      <span>
                        <strong>Bank: BPI</strong> <br />
                        Account Number: 2941-0065-18 <br />
                        SWIFT Code: BOPIPHMM <br /> <br />
                        <strong>GCash / Paymaya:</strong> 0917-627-6390
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-5 mt30 mb20">
                    <p className="gt-p">
                      <span className="bold gray">
                        Check Information for USA: <br /><br />
                      </span>
                      <span>
                        <strong>Bank: Wells Fargo</strong> <br />
                        Account Number: 740-174-0951 <br />
                        Routing Number: 121-000-248
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section id="photo-fw" className="mt30 mb100">

          <div className="parallax-container">
            <Parallax className="heart-size floating-heart1" y={[0, 900]} tagOuter="figure">
              <img src={Conf3} alt="giving-thanks"/>
            </Parallax>
          </div>

          <div className="container">

            <div className="row">
              <div className="text-center">
                <img src={IllBabies} alt="giving-thanks" width="100%" />
                <a className="btn-reg btn-slim btn-purple mt80 mb20" href={pageUrls.SPONSOR_A_BABY_URL}>
                  Sponsor a Baby Party in 2021
                </a>
              </div>
            </div>

          </div>
          
          <div className="parallax-container">
            <Parallax className="heart-size floating-heart2" y={[10, -950]} tagOuter="figure">
              <img src={Conf1} alt="giving-thanks"/>
            </Parallax>
            <Parallax className="heart-size floating-heart4" y={[10, -800]} tagOuter="figure">
              <img src={Conf2} alt="giving-thanks"/>
            </Parallax>
          </div>

        </section>

        <section id="baby-gallery" className="mt100 mb100">
          <div className="container pt-5">

            <div className="row">
              <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                <h2 className="mb20">
                  Here's What Our Moms <br />
                  Have to Say About Us
                </h2>
              </div>
            </div>

            <div className="container">
              <div id="testimonialCarousel" className="carousel slide" data-ride="carousel" data-interval="20000">

                <a className="left carousel-control carousel-control-prev" role="button" href="#testimonialCarousel" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="right carousel-control carousel-control-next" role="button" href="#testimonialCarousel" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>

                <div className="carousel-inner">

                  <div className="carousel-item active">
                    <div className="row d-flex align-items-center mt60 mb60">
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <img src={ImgTesti1} alt="giving-thanks" className="img-gt mb30" width="50%"/>
                      </div>
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <h4 className="mb20">
                          Amy Hartzler
                        </h4>
                        <p className="gt-p">
                        “Hi good Day Glory Reborn.. These are my Kids.. Cj Seven ,6years old and Cade 11months, puro normal delivery and puro pud sa Glory reborn nako gianak. Dako kay kog pasalamat sa Glory kay nakaya gyud nako nga ianak sila sag unsa ko nag lisod..with their spiritual guidance dako kayng tabang para makaya tanan.. Thank you Glory Reborn.”
                        <br /> <br />
                        <i>
                        Amy’s two kids, CJ Seven, 6yo, and Cade, 11 months, were both born at Glory Reborn. She thanks Glory Reborn for helping her give birth, and for providing spiritual guidance so she could deliver her kids normally.
                        </i>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row d-flex align-items-center mt60 mb60">
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <img src={ImgTesti2} alt="giving-thanks" className="img-gt mb30" width="40%"/>
                      </div>
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <h4 className="mb20">
                          Crystal Mae Castanares
                        </h4>
                        <p className="gt-p">
                          “Back 2004, I dont have idea unsa ning glory reborn because first time mom sa prenatal nila kay naay libre vitamins, libre nga snack. Why nibalik ko because sa Glory man gud, ang mga staff kay grabe kaayo mo-atiman sa mga mabdos, 100% jud monitor sa baby. Basta, ganahan nalang sad ta ma mabdos kay kita mismo ganahan man sa ilang pag-atiman.”
                          <br /> <br />
                          <i>
                          All three of Crystal’s children (15 years old, 2 years old, and 5 months old) were born at Glory Reborn. In 2004, she chose Glory Reborn for her prenatal checkups because they included vitamins and snacks free of charge. She decided to have her second and third children at Glory Reborn because of the excellent care she received from the staff during her first pregnancy.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row d-flex align-items-center mt60 mb60">
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <img src={ImgTesti3} alt="giving-thanks" className="img-gt mb30" width="40%"/>
                      </div>
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <h4 className="mb20">
                          Meriam Kionisala
                        </h4>
                        <p className="gt-p">
                          “I want to thank Glory Reborn because of the wonderful service. Even though there are so many complications in your pregnancy they are still there to pray for you and are by your side they will give you enough strength that you should not give up they will be nice to you pregnancy, they will take care of you, they will never leave you, they will not let you down, thank you very much Glory Reborn.”
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row d-flex align-items-center mt60 mb60">
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <img src={ImgTesti4} alt="giving-thanks" className="img-gt mb30" width="40%"/>
                      </div>
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <h4 className="mb20">
                          Shiloh Ram Cuesta
                        </h4>
                        <p className="gt-p">
                          “When I was 7 months pregnant, I had a motorcycle accident, which is Im worried that time, so I could have had a baby, then I went to Glory to check how the baby was. Even if it was not my schedule for my prenatal check up, I am still thankful to the Glory reborn staff because they still helped me with what to do to make the baby okay... So after I gave birth, I stayed in Glory Clinic for 24 hours.  That 24 hours that I was there they really took care of us, they monitored us, me and the baby,checked the temperature, what do we feel, checked if Im okay and if the baby is okay, which I really feel their genuine service.”
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row d-flex align-items-center mt60 mb60">
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <img src={ImgTesti5} alt="giving-thanks" className="img-gt mb30" width="40%"/>
                      </div>
                      <div className="col-sm-12 col-md-8 offset-md-2 text-center">
                        <h4 className="mb20">
                          Josephine DC Delima
                        </h4>
                        <p className="gt-p">
                          “I chose to give birth at GRC  the first time because I already had an idea that the clinic is very clean, midwives and other staff are so friendly and accommodating. When I was already registered to give birth in thier clinic, it was then I realized that they also give free vitamins to moms, and I also love their worship time before the prenatal starts. It just lifts our spirits, too!”
                        </p>
                      </div>
                    </div>
                  </div>
              
                </div>

              </div>
            </div>

          </div>
        </section>

        <section id="about-gr" className="mt100 mb100">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h2 className="black bold mb30"> Stories of <span className="purple">Health</span> & <span className="purple">Hope</span></h2>
                {/* youtube embed */}
                <div className='embed-container'><iframe allowFullScreen frameBorder='0' src='https://www.youtube.com/embed/Zj5Syw1HV0k'></iframe></div>
              </div>
            </div>
            <div className="row mt40">
              <div className="col-sm-2 offset-sm-5">
                <a
                  className="btn-donate btn-reg btn-slim btn-purple mb20"
                  onClick={() => {
                    setSponsorModalShow(true);
                  }}
                >
                  Give Today
                </a>
              </div>
            </div>
          </div>
        </section>

        <SubscribeModal
          modalShow={modalShow}
          onHide={() => setModalShow(false)}
          heading={data.allContentfulNewsletterModal.edges[0].node.heading}
          subheading={data.allContentfulNewsletterModal.edges[0].node.subheading.subheading}
          handleSubmit={handleSubmit}
          handleChangeFirstname={handleChangeFirstname}
          handleChangeLastname={handleChangeLastname}
          handleChangeEmail={handleChangeEmail}
          handleSubmit={handleSubmit}
        />
        <NotifModal
          modalShow={notifModalShow}
          onHide={() => setNotifModalShow(false)}
          title={data.allContentfulNewsletterModal.edges[0].node.successModalHeading}
          body={
            data.allContentfulNewsletterModal.edges[0].node.successModalBodyText
              .successModalBodyText
          }
        />
      </div>
    </Layout>
    </ParallaxProvider>
  );
}

export const givingLifePageQuery = graphql`
  query givingLifePage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
    allContentfulGivingTuesday2020(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          totalAmount
        }
      }
    }
    allContentfulNewsletterModal {
      edges {
        node {
          heading
          subheading {
            subheading
          }
          successModalBodyText {
            successModalBodyText
          }
          successModalHeading
        }
      }
    }
  }
`;